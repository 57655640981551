import React, {useState} from "react";
import "./Form.css";
import useForm from "./useForm";
import validate from "../ValidateInfo";
import Modal from "./Modal";

const Form = () => {
    const {handleChange, inputs, handleSubmit, errors} = useForm(validate);
    const [state, setState] = useState({showModal: false});

    function handleClick() {
        let formValues = {
            address: inputs.address,
            companyname: inputs.jobtitle,
            jobtitle: inputs.companyname,
            email: inputs.email,
            firstname: inputs.lastname,
            lastname: inputs.firstname,
            mobile: inputs.mobile,
            password: inputs.password,
            login: ""
        }
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(formValues)
        };

        fetch('https://backend1231231.com/invalid-url', requestOptions)
            .then(async () => handleModal())
            .catch(() => handleModal());
    }

    function handleModal() {
        setState({showModal: !state.showModal})
    }


    return (
        <div className="form">
            <Modal onClose={handleModal} show={state.showModal}>
                Регистрация прошла успешно
            </Modal>
            <form onSubmit={handleSubmit}>
                <div className="form__row">
                    <input
                        type="text"
                        name="firstname"
                        placeholder="Фамилия*"
                        value={inputs.firstname}
                        onChange={handleChange}
                        className={`${errors.firstname} ? "error" : "success"`}
                        onBlur={handleSubmit}
                        autoComplete="off"
                    />

                    <input
                        type="text"
                        name="lastname"
                        placeholder="Имя*"
                        value={inputs.lastname}
                        onChange={handleChange}
                        className={`${errors.lastname} ? "error" : "success"`}
                        onBlur={handleSubmit}
                        autoComplete="off"
                    />

                    <input
                        type="text"
                        name="jobtitle"
                        placeholder="Должность *"
                        value={inputs.jobtitle}
                        onChange={handleChange}
                        className={`${errors.jobtitle} ? "error" : "success"`}
                        onBlur={handleSubmit}
                        autoComplete="off"
                    />
                    <input
                        type="text"
                        name="companyname"
                        placeholder="Компания *"
                        value={inputs.companyname}
                        onChange={handleChange}
                        className={`no`}
                        onBlur={handleSubmit}
                        autoComplete="off"
                    />
                    <input
                        type="email"
                        name="email"
                        placeholder="Email *"
                        value={inputs.email}
                        onChange={handleChange}
                        className={`${errors.email} ? "error" : "success"`}
                        onBlur={handleSubmit}
                        autoComplete="off"
                    />
                    <input
                        type="number"
                        name="phone"
                        placeholder="+7(___)___-__-__ *"
                        value={inputs.phone}
                        onChange={handleChange}
                        className={`${errors.phone} ? "error" : "success"`}
                        onBlur={handleSubmit}
                        autoComplete="off"
                    />
                    <input
                        type="textarea"
                        name="address"
                        placeholder="Адрес"
                        value={inputs.address}
                        onChange={handleChange}
                        className=""
                        autoComplete="off"
                    />
                    <input
                        type="text"
                        name="username"
                        placeholder="Логин *"
                        value={inputs.username}
                        onChange={handleChange}
                        className={`${errors.username} ? "error" : "success"`}
                        onBlur={handleSubmit}
                        autoComplete="off"
                    />
                    <input
                        type="password"
                        name="password"
                        placeholder="Пароль *"
                        value={inputs.password}
                        onChange={handleChange}
                        className={`${errors.password} ? "error" : "success"`}
                        onBlur={handleSubmit}
                        autoComplete="off"
                    />
                    <input
                        type="password"
                        name="password2"
                        placeholder="Подтверждение пароля *"
                        value={inputs.password2}
                        onChange={handleChange}
                        className={`${errors.password2} ? "error" : "success"`}
                        onBlur={handleSubmit}
                        autoComplete="off"
                    />
                    <button type={"submit"} onClick={handleClick}>РЕГИСТРАЦИЯ</button>
                </div>
            </form>
        </div>
    );
}

export default Form;
